<template>
    <div class="page-header" >
        <h2>{{title}}</h2>          
    </div>
</template>

<script>

export default ({
    name:"PortalTitle",
    props:['title']
})
</script>

<style scoped>
.page-header{
    background-color: #FAFAFA;
    background-size: 65%;
    width: 100%;
    height: 56px;
    padding-left: 70px;
    margin-right: 70px;
    padding-top: 10px;
    display: block;
    color: #00475A;
    position:fixed;
    Z-index:10;
    text-transform: uppercase;
    
}
</style>
