import authHeader from './AuthHeader'
import axios from "axios";

const API_URL = process.env.VUE_APP_MSCORE_URL

class ApplicationService{

    getOwnApplication(userId){
        return axios.get(`${API_URL}/apps/agent/${userId}`,{headers: authHeader()})
    }

    getSupApplication(){
        return axios.get(`${API_URL}/apps/opinion`,{headers: authHeader()})
    }

    getSupResolvedApplication(){
        return axios.get(`${API_URL}/apps/status`,{headers: authHeader()})
    }

    getOwnApplicationResolved(userId){
        return axios.get(`${API_URL}/apps/resolved/agent/${userId}`,{headers: authHeader()})
    }

    assignApplication(userId){
        return axios.get(`${API_URL}/affectations/${userId}`,{headers: authHeader()})
    }

    save(payload){
        return axios.post(`${API_URL}/applicationonly`, payload,{headers: authHeader()})
    }

    resend(applyNumber){
        return axios.get(`${API_URL}/visa/send/${applyNumber}`,{headers: authHeader()})
    }

    getOwnResolvedApplication(){

    }

    getOwnRGuardApplication(){
        
    }

    getOwnStat(){
        
    }

    getPassport(id){
        return axios.get(`${API_URL}/passeport/applicant/${id}`,{headers: authHeader()})
    }


    getContact(id){
        return axios.get(`${API_URL}/contact/applicant/${id}`,{headers: authHeader()})
    }
}

export default new ApplicationService()