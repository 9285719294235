<template>   
    <v-layout class="justify-center">
        <Header />
        <v-card outlined color="transparent" width="84%">
            <!--Debut composant Tab-->
            <v-tabs
                v-model="tab"
                white
                class="ml-0"
            >
                <v-tabs-slider></v-tabs-slider>
                <!--Debut entete Tab-->
                <v-tab href="#dashboard">
                    Dashboard
                </v-tab>

                <v-tab href="#demandes">
                    Demandes
                </v-tab>

                <v-tab href="#dosiertraiter">
                    Dossier traités
                </v-tab>

                <v-tab href="#dossierfrontiere">
                    Dossier à la frontière
                </v-tab>
                <!--Fin entete Tab-->

            </v-tabs>
            <v-tabs-items v-model="tab">
                <v-tab-item :key="1" value="dashboard">
                    <v-card 
                    elevation="6"
                    outlined
                    class="mt-7 rounded-lg"
                    >
                        <v-card-text class="pt-2 pl-2 pr-2 pb-2">
                            <v-card class="rounded-lg" height="50">
                                <v-card-title class="justify-center pt-1">
                                    <h4 class="pt-0" style="font-family: inherit;">Statistique</h4>
                                </v-card-title>
                            </v-card>
                            <v-card rounded-lg class="col mt-3" color="#d3d3d3">
                                <v-row heigth="50">
                                    <v-card class="col-6 white ma-2">
                                        <v-card-title class="justify-center l-0">
                                            <h5>Personnelles</h5>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-row>
                                                <v-col class="pr-1 pl-0">
                                                    <CardNumber 
                                                    background= "grey"
                                                    icon="mdi-arrow-down-thick"
                                                    color="white"
                                                    :nbr="4" 
                                                    />
                                                </v-col>
                                                <v-col class="pr-1 pl-0">
                                                    <CardNumber 
                                                    background= "grey"
                                                    icon="mdi-check-bold"
                                                    color="white"
                                                    :nbr="4" 
                                                    />
                                                </v-col>
                                                <v-col class="pr-1 pl-0">
                                                    <CardNumber 
                                                    background= "grey"
                                                    icon="mdi-folder-outline"
                                                    color="blue dark-1"
                                                    :nbr="4" 
                                                    />
                                                </v-col>
                                                <v-col class="pr-0 pl-0">
                                                    <CardNumber 
                                                    background= "grey"
                                                    icon="mdi-folder"
                                                    color="blue dark-1"
                                                    :nbr="4" 
                                                    />
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                    <v-card class="col ma-2">
                                        <v-card-title class="text-center">
                                            <h5>Alert sur vos dossiers</h5>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-row>
                                                <v-col class="pr-1 pl-0">
                                                    <CardNumber 
                                                    background= "red"
                                                    icon="mdi-folder-outline"
                                                    color="blue dark-1"
                                                    :nbr="4" 
                                                    />
                                                </v-col>
                                                <v-col class="pr-1 pl-0">
                                                    <CardNumber 
                                                    background= "orange"
                                                    icon="mdi-folder-outline"
                                                    color="blue dark-1"
                                                    :nbr="4" 
                                                    />
                                                </v-col>
                                                <v-col class="pr-0 pl-0">
                                                    <CardNumber 
                                                    background= "green"
                                                    icon="mdi-folder-outline"
                                                    color="blue dark-1"
                                                    :nbr="4" 
                                                    />
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                    <v-card class="col ma-2">
                                        <v-card-title class="col text-center">
                                            <h5>Délai moyen de réponse</h5>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-row>
                                                <v-col>
                                                    <CardNumber 
                                                    background= "red"
                                                    icon="mdi-folder-outline"
                                                    color="blue dark-1"
                                                    :nbr="4" 
                                                    />
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-row>
                            </v-card>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
                <v-tab-item :key="2" value="demandes">
                    <v-container
                    id="scroll-target"
                    style="max-height: 500px; max-width: 100%"
                    class="overflow-y-auto mt-5"
                    >
                        <template>
                            <v-btn color="primary" dark class="mb-2">
                                M'assigner des dossiers
                            </v-btn>
                        </template>
                        <v-container grid-list-md>
                            <v-form lazy-validation ref="loginInfo">
                                <v-layout>
                                    <v-col xs12 sm3 md3>
                                        <v-text-field   
                                            label="Tracking number"
                                            hide-details="auto"
                                        ></v-text-field>
                                    </v-col>

                                    <v-col xs12 sm3 md3>
                                        <v-text-field
                                            label="Name"
                                            hide-details="auto"
                                        ></v-text-field>
                                    </v-col>
                                    
                                    <v-col xs12 sm3 md3>
                                        <v-select
                                        :items="priorityItems"
                                        label="Toutes priorités"
                                        hide-details="auto"
                                        ></v-select>
                                    </v-col>
                                    <v-col class="text-center" xs12 sm3 md3>
                                        <v-btn color="secondary" justify="center" class="white--text" @click="validateLogin">
                                            Recherche
                                        </v-btn>
                                    </v-col>
                                </v-layout>   
                            </v-form>
                        </v-container>
                    <template>
                            
                    </template>
                        <!-- Début DataTable -->
                        <v-data-table
                            :headers="headersRequest"
                            class="elevation-1"
                        >
                            <template v-slot:item="{ item }">
                                <tr>
                                    <td>{{item.demande.numero_demande}}</td>
                                    <td>{{item.demande.demandeur}}</td>
                                    <td>{{item.demande.date_demande}}</td>
                                    <td>{{item.demande.date_arrivee}}</td>
                                    <td>{{item.demande.date_depart}}</td>
                                    <td>{{item.demande.priorite}}</td>
                                    <td>
                                        Détails
                                    </td>
                                </tr>
                            </template>
                            <template v-slot:no-data>
                                <p>Aucune donnée n'a été enrégistré pour le moment.</p>
                            </template>
                        </v-data-table>
                        <!-- Fin DataTable -->
                    </v-container>
                        
                </v-tab-item>
                <v-tab-item :key="3" value="dosiertraiter">
                    <v-container
                    id="scroll-target"
                    style="max-height: 500px; max-width: 100%"
                    class="overflow-y-auto mt-5"
                    >
                        <v-container grid-list-md>
                            <v-form lazy-validation ref="loginInfo">
                                <v-layout>
                                    <v-col xs12 sm4 md4>
                                        <v-text-field   
                                            label="Tracking number"
                                            hide-details="auto"
                                        ></v-text-field>
                                    </v-col>

                                    <v-col xs12 sm4 md4>
                                        <v-text-field
                                            label="Name"
                                            hide-details="auto"
                                        ></v-text-field>
                                    </v-col>
                                    
                                    <v-col xs12 sm3 md3>
                                        <v-btn color="secondary" left class="white--text" @click="validateLogin">
                                            Recherche
                                        </v-btn>
                                    </v-col>
                                </v-layout>   
                            </v-form>
                        </v-container>
                        <!-- Début DataTable -->
                        <v-data-table
                            :headers="headersTreat"
                            class="elevation-1"
                        >
                            <template v-slot:item="{ item }">
                                <tr>
                                    <td>{{item.demande.numero_demande}}</td>
                                    <td>{{item.demande.demandeur}}</td>
                                    <td>{{item.demande.date_demande}}</td>
                                    <td>{{item.demande.avis}}</td>
                                    <td>{{item.demande.date_avis}}</td>
                                    <td>{{item.demande.decision}}</td>
                                    <td>{{item.demande.date_decision}}</td>
                                    <td>
                                        Détails
                                    </td>
                                </tr>
                            </template>
                            <template v-slot:no-data>
                                <p>Aucune donnée n'a été enrégistré pour le moment.</p>
                            </template>
                        </v-data-table>
                        <!-- Fin DataTable -->
                    </v-container>
                </v-tab-item>
                <v-tab-item :key="4" value="dossierfrontiere">
                    <v-container
                    id="scroll-target"
                    style="max-height: 500px; max-width: 100%"
                    class="overflow-y-auto mt-5"
                    >
                        <v-container grid-list-md>
                            <v-form lazy-validation ref="loginInfo">
                                <v-layout>
                                    <v-col xs12 sm4 md4>
                                        <v-text-field   
                                            label="Tracking number"
                                            hide-details="auto"
                                        ></v-text-field>
                                    </v-col>

                                    <v-col xs12 sm4 md4>
                                        <v-text-field
                                            label="Name"
                                            hide-details="auto"
                                        ></v-text-field>
                                    </v-col>
                                    
                                    <v-col xs12 sm3 md3>
                                        <v-btn color="secondary" left class="white--text">
                                            Recherche
                                        </v-btn>
                                    </v-col>
                                </v-layout>   
                            </v-form>
                        </v-container>
                        <!-- Début DataTable -->
                        <v-data-table
                            :headers="headersFrontier"
                            class="elevation-1"
                        >
                            <template v-slot:item="{ item }">
                                <tr>
                                    <td>{{item.demande.numero_demande}}</td>
                                    <td>{{item.demande.date_demande}}</td>
                                    <td>{{item.demande.date_arrivee}}</td>
                                    <td>{{item.demande.date_depart}}</td>
                                    <td>{{item.demande.statut}}</td>
                                    <td>{{item.demande.date_statut}}</td>
                                    <td>
                                        Détails
                                    </td>
                                </tr>
                            </template>
                            <template v-slot:no-data>
                                <p>Aucune donnée n'a été enrégistré pour le moment.</p>
                            </template>
                        </v-data-table>
                        <!-- Fin DataTable -->
                    </v-container>
                </v-tab-item>
            </v-tabs-items>
            <!--Fin composant Tab-->
        </v-card>  
    </v-layout>
</template>
<script>
import CardNumber from "@/components/CardNumber"
import Demande from "@/models/Demande"
import Header from "@/components/Header"
  export default {
    name: 'BasicDashboard',
    components: {
        CardNumber
    },
    data () {
      return {
        tab: "subscribe",
        demande: new Demande,
        components: {
            Header
        },
        headersRequest: [
            {
                text: "N° Demande",
                align: "left",
                sortable: true,
                value: "demande.numero_demande"
            },
            { text: "Demandeur", value: "demandeur" },
            { text: "Date de la demande", value: "date_demande" },
            { text: "Date arrivée", value: "date_arrivee" },
            { text: "Date depart", value: "date_depart" },
            { text: "Priorité", value: "priorite" },
            { text: "Actions", value: "action", sortable: false }
        ],
        headersTreat: [
            {
                text: "N° Demande",
                align: "left",
                sortable: true,
                value: "demande.numero_demande"
            },
            { text: "Demandeur", value: "demandeur" },
            { text: "Date de la demande", value: "date_demande" },
            { text: "Avis", value: "avis" },
            { text: "Date de l'avis", value: "date_avis" },
            { text: "Décision", value: "decision" },
            { text: "Date de la décision", value: "date_decision" },
            { text: "Actions", value: "action", sortable: false }
        ],
        headersFrontier: [
            {
                text: "N° Demande",
                align: "left",
                sortable: true,
                value: "demande.numero_demande"
            },
            { text: "Date de la demande", value: "date_demande" },
            { text: "Date d'arrivée", value: "date_arrivee" },
            { text: "Date départ", value: "date_depart" },
            { text: "Statut à l'arrivée", value: "statut" },
            { text: "Date du statut", value: "date_statut" },
            { text: "Actions", value: "action", sortable: false }
        ],
        priorityItems: ["Toutes priorités", "Prioritaire", "Urgent", "Non urgent"],
      }
    },
  }
</script>
<style scoped>
h5 {
    color: black;
}
</style>