//import axios from "axios";
//const CORE_API_URL = process.env.VUE_APP_MSCORE_URL


const state = () => ({
    app: null
});

const getters = {

    getApp(state){
        if(state.app){
            return state.app
        }else{
            let json = localStorage.getItem("app")
            if(json != null){
                return JSON.parse(json)
            }
        }
        return null;
    }


}

const mutations = {
    setApp(state, data) {
       state.app = data
       localStorage.setItem("app", JSON.stringify(data))
    },

}

const actions = {    

}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}