<template>
  <v-app>
    <portal-sysbar></portal-sysbar>
    <div class="main-content">
      <portal-aside></portal-aside>
      <portal-title title="Mes demandes"></portal-title>
      <div class="content">
        <v-card flat>
          <v-card-title>
            <v-btn color="primary" @click="assign()" v-if="isBasic()"
              >M'assigner des demandes</v-btn
            >
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="applications"
              :items-per-page="10"
              :search="search"
              class="elevation-1"
              :loading="loading"
            >
              <template v-slot:[`item.createdAt`]="{ item }">
                {{ format(item.createdAt) }}
              </template>
              <template v-slot:[`item.journey.exitDate`]="{ item }">
                {{ format(item.journey.exitDate) }}
              </template>
              <template v-slot:[`item.journey.entryDate`]="{ item }">
                {{ format(item.journey.entryDate) }}
              </template>
              <template v-slot:[`item.journey`]="{ item }">
                <v-chip :color="getPriority(item.journey).color">
                  {{ getPriority(item.journey).value }}
                </v-chip>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon small class="mr-2" @click="displayItem(item)">
                  mdi-pencil
                </v-icon>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </v-app>
</template>

<script>
import PortalSysbar from "@/components/PortalSysbar.vue";
import PortalAside from "@/components/PortalAside.vue";
import PortalTitle from "@/components/PortalTitle.vue";
import { mapGetters, mapMutations } from "vuex";
import ApplicationService from "@/services/ApplicationService";
import { formatDate, todayDiff } from "@/shared/DateHelper";
export default {
  name: "ApplicationPage",
  components: {
    PortalSysbar,
    PortalAside,
    PortalTitle,
  },
  data() {
    return {
      search: "",
      applications: [],
      loading: true,
      headers: [
        {
          text: "Numero de la demande",
          align: "start",
          sortable: false,
          value: "application.trackingNumber",
        },
        { text: "Demandeur", value: "applicant.fullname" },
        { text: "Date de la demande", value: "createdAt" },
        { text: "Date arrivée", value: "journey.entryDate" },
        { text: "Date depart", value: "journey.exitDate" },
        { text: "Priorité", value: "journey" },
        { text: "Actions", value: "actions" },
      ],
    };
  },

  methods: {
    ...mapMutations("app", {
      saveApp: "setApp",
    }),

    isBasic() {
      let find = this.getterRoles.find((e) => e.authority == "basic");
      if (find !== undefined) {
        return true;
      }
      return false;
    },

    assign() {
      ApplicationService.assignApplication(this.getterUser.id).then(
        () => {
            this.loadApplication();
        }
      );
    },

    displayItem(application) {
      this.saveApp(application);
      this.$router.push("/details");
    },

    getName(applicant) {
      return applicant.name + " " + applicant.surname;
    },

    format(date) {
      return formatDate(date);
    },

    getPriority(journey) {
      let day = todayDiff(journey.entryDate);
      console.log(day)
      if (day < 4) {
        return {
          value: "Haute",
          color: "warning",
        };
      }

      if (day < 7) {
        return {
          value: "Moyen",
          color: "yellow",
        };
      }

      return {
        value: "Normal",
        color: "default",
      };
    },

    loadApplication() {
      this.loading = true;
      if (this.isBasic()) {
        ApplicationService.getOwnApplication(this.getterUser.id)
          .then((response) => {
            this.applications = response.data;
            this.loading = false;
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
          });
      } else {
        ApplicationService.getSupApplication()
          .then((response) => {
            this.applications = response.data;
            this.loading = false;
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
          });
      }
    },
  },
  mounted() {
    this.loadApplication();
  },
  computed: {
    ...mapGetters("auth", {
      getterUser: "getUser",
      getterRoles: "getRoles",
    }),
  },
};
</script>
