import moment from 'moment-timezone'

export function parseDate(date){
    return new Date(date + "T00:00:00")
}

export function dateDiff(date1, date2){
    return (parseDate(date1) - parseDate(date2)) / (1000*60*60*24)
}

export function todayDiff(date){
    console.log(new Date(date))
    return (new Date(date).getTime() - Date.now()) / (1000*60*60*24)
}

export function formatDate(date) {
    moment.locale("fr")
    return moment(date).format("L")
}

export function firstDateOfCurrentMonth() {
    let current_date = new Date()
    let year = current_date.getFullYear()
    let month = current_date.getMonth() + 1
    if (month < 10) {
        return year + "-0" + month + "-01" ;
    }
    return year + "-" + month + "-01" ;
}

export function numStr(a, b) {
    a = '' + a;
    b = b || ' ';
    var c = '',
        d = 0;
    while (a.match(/^0[0-9]/)) {
        a = a.substr(1);
    }
    for (var i = a.length - 1; i >= 0; i--) {
        c = (d != 0 && d % 3 == 0) ? a[i] + b + c : a[i] + c;
        d++;
    }
    return c;
}

