<template>
  <v-app>
    <portal-sysbar></portal-sysbar>
    <div class="main-content">
      <portal-aside></portal-aside>
      <portal-title title="Demandes traitées"></portal-title>
      <div class="content row">
        <v-col class="mb-5">
          <v-card height="100%">
            <v-card-title>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-card-text>
              <v-data-table
                :headers="headers"
                :items="applications"
                :items-per-page="10"
                :search="search"
                :loading="loading"
                class="elevation-1"
              >
                <template v-slot:[`item.applicant`]="{ item }">
                  {{ getName(item.applicant) }}
                </template>
                <template v-slot:[`item.application.status`]="{ item }">
                  <v-chip :color="getColor(item.application.status)">
                    {{ getStatus(item.application.status) }}
                  </v-chip>
                </template>
                <template v-slot:[`item.action`]="{ item }">
                  <v-icon small class="mr-2" @click="displayItem(item)">
                    mdi-eye
                  </v-icon>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col class="mb-5" v-if="showDetail">
          <v-alert
            :color="response.status"
            text
            dense
            dismissible
            v-model="response.show"
          >
            {{ response.message }}
          </v-alert>
          <v-card height="100%">
            <v-system-bar
              :color="getColor(application.application.status)"
              dark
            >
              <v-spacer></v-spacer>
              <v-icon @click="close">mdi-close</v-icon>
            </v-system-bar>
            <v-card-title>
              Demande N°
              <span>{{ application.application.trackingNumber }}</span>
              <v-spacer></v-spacer> <b>Decision:</b
              ><span>{{
                getStatus(application.application.status)
              }}</span></v-card-title
            >
            <v-card-text>
              <v-btn
                color="success"
                class="mb-5"
                v-show="application.application.status"
                @click="sendMail(application.application.trackingNumber)"
                >Renvoyer le mail confirmation</v-btn
              >
              <p class="title">Informations personnelles</p>
              <v-row>
                <v-col col>
                  <v-row class="infoU">
                    <v-col col>Nom:</v-col>
                    <v-col col>{{ application.applicant.name }}</v-col>
                  </v-row>
                  <v-row class="infoU">
                    <v-col col>Prenoms:</v-col>
                    <v-col col>{{ application.applicant.surname }}</v-col>
                  </v-row>
                  <v-row class="infoU">
                    <v-col col>Sexe:</v-col>
                    <v-col col>{{
                      getGender(application.applicant.gender)
                    }}</v-col>
                  </v-row>
                  <v-row class="infoU">
                    <v-col col>Situation matr.:</v-col>
                    <v-col col>{{ application.applicant.maritalStatus }}</v-col>
                  </v-row>
                  <v-row class="infoU">
                    <v-col col>Profession:</v-col>
                    <v-col col>{{ application.applicant.job }}</v-col>
                  </v-row>
                </v-col>
                <v-col col>
                  <v-row class="infoU">
                    <v-col col>Date de naissance:</v-col>
                    <v-col col>{{
                      format(application.applicant.birthdate)
                    }}</v-col>
                  </v-row>
                  <v-row class="infoU">
                    <v-col col>Lieu de naissance:</v-col>
                    <v-col col>{{ application.applicant.birthplace }}</v-col>
                  </v-row>
                  <v-row class="infoU">
                    <v-col col>Pays de naissance:</v-col>
                    <v-col col>{{ application.applicant.birthstate.fr }}</v-col>
                  </v-row>
                  <v-row class="infoU">
                    <v-col col>Nationalité d'orig.:</v-col>
                    <v-col col>{{
                      application.applicant.originNationality.fr
                    }}</v-col>
                  </v-row>
                  <v-row class="infoU">
                    <v-col col>Nationalité Act.:</v-col>
                    <v-col col>{{
                      application.applicant.actualNationality.fr
                    }}</v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-divider class="my-5"></v-divider>
              <v-row>
                <v-col col>
                  <v-row class="infoU">
                    <v-col col>Adresse:</v-col>
                    <v-col col>{{ contact.line }}</v-col>
                  </v-row>
                  <v-row class="infoU">
                    <v-col col>Ville:</v-col>
                    <v-col col>{{ contact.city }}</v-col>
                  </v-row>
                  <v-row class="infoU">
                    <v-col col>Code postal:</v-col>
                    <v-col col>{{ contact.cp }}</v-col>
                  </v-row>
                </v-col>

                <v-col col>
                  <v-row class="infoU">
                    <v-col col>Pays:</v-col>
                    <v-col col>{{ contact.state.fr }}</v-col>
                  </v-row>
                  <v-row class="infoU">
                    <v-col col>Telephone:</v-col>
                    <v-col col>{{ contact.phone }}</v-col>
                  </v-row>
                  <v-row class="infoU">
                    <v-col col>Mail:</v-col>
                    <v-col col>{{ application.applicant.email }}</v-col>
                  </v-row>
                </v-col>
              </v-row>
              <p class="title mt-5">Passeport - Informations voyage</p>

              <v-row>
                <v-col col>
                  <v-row class="infoU">
                    <v-col col>Numero:</v-col>
                    <v-col col>{{ application.passport.number }}</v-col>
                  </v-row>
                  <v-row class="infoU">
                    <v-col col>Delivré par:</v-col>
                    <v-col col>{{ application.passport.delivryState.fr }}</v-col>
                  </v-row>
                  <v-row class="infoU">
                    <v-col col>Emis le:</v-col>
                    <v-col col>{{ format(application.passport.delivryDate) }}</v-col>
                  </v-row>
                  <v-row class="infoU">
                    <v-col col>Expire le:</v-col>
                    <v-col col>{{ format(application.passport.expiredDate) }}</v-col>
                  </v-row>
                </v-col>
                <v-col col>
                  <v-row class="infoU">
                    <v-col col>Motif:</v-col>
                    <v-col col>{{ application.journey.reason }}</v-col>
                  </v-row>
                  <v-row class="infoU">
                    <v-col col>Arrivée:</v-col>
                    <v-col col>{{
                      format(application.journey.entryDate)
                    }}</v-col>
                  </v-row>
                  <v-row class="infoU">
                    <v-col col>Départ:</v-col>
                    <v-col col>{{
                      format(application.journey.exitDate)
                    }}</v-col>
                  </v-row>
                  <v-row class="infoU">
                    <v-col col>Justificatif:</v-col>
                    <v-col col>
                      <a
                        href="#"
                        @click="loadFile(application.passport.passport)"
                        class="mr-3"
                        >Passeport</a
                      >
                      <a
                        href="#"
                        @click="loadFile(application.application.ticket)"
                        >Billet</a
                      ></v-col
                    >
                  </v-row>
                </v-col>
              </v-row>
              <p class="title mt-5">Adresse a Djibouti</p>
              <v-row>
                <v-col col>
                  <v-row class="infoU">
                    <v-col col>Adresse:</v-col>
                    <v-col col>{{ application.journey.stayAddress }}</v-col>
                  </v-row>
                  <v-row class="infoU">
                    <v-col col>Ville:</v-col>
                    <v-col col>{{ application.journey.stayCity }}</v-col>
                  </v-row>
                </v-col>
                <v-col col>
                  <v-row class="infoU">
                    <v-col col>Telephone:</v-col>
                    <v-col col>{{ application.journey.stayPhone }}</v-col>
                  </v-row>
                  <v-row class="infoU">
                    <v-col col>Justificatif:</v-col>
                    <v-col col>
                      <a
                        href="#"
                        class="mr-3"
                        @click="loadFile(application.journey.reservation)"
                        >Lettre d'invitation</a
                      ></v-col
                    >
                  </v-row>
                </v-col>
              </v-row>
              <v-divider class="mt-15"></v-divider>
              <v-row class="pt-5">
                <v-col> <b>Traité par: </b> 
                <span>{{application.application.agent.fullname}} </span>
                <span> <b>le </b> {{ format(application.application.opinionDAte)}}</span> </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </div>
    </div>
    <v-dialog v-model="imgdialog" width="60%">
      <v-sheet color="#fff" class="justify-center d-flex">
        <viewer ref="viewer" :options="options">
          <img :src="imgSrc" />
        </viewer>
      </v-sheet>
    </v-dialog>

    <v-dialog v-model="pdfdialog" width="60%">
      <v-sheet color="#fff">
        <div class="justify-center d-flex py-5">
          <v-btn @click="rotate += 90" class="mr-3" color="secondary">
            <v-icon>mdi-reload</v-icon>
          </v-btn>
          <v-btn @click="rotate -= 90" class="mr-3" color="secondary">
            <v-icon>mdi-restore</v-icon>
          </v-btn>
          <v-col md="1" class="py-0">
            <v-text-field
              v-model.number="page"
              type="number"
              outlined
              hide-details
              dense
              class="col-1"
            ></v-text-field>
          </v-col>
          /{{ numPages }}
          <v-btn @click="$refs.pdf.print()">
            <v-icon>mdi-printer</v-icon>
          </v-btn>
        </div>

        <pdf
          ref="pdf"
          :src="src"
          :rotate="rotate"
          :page="page"
          @num-pages="numPages = $event"
          @link-clicked="page = $event"
        ></pdf>
      </v-sheet>
    </v-dialog>
  </v-app>
</template>
<script>
import PortalSysbar from "@/components/PortalSysbar.vue";
import PortalAside from "@/components/PortalAside.vue";
import PortalTitle from "@/components/PortalTitle.vue";
import ApplicationService from "@/services/ApplicationService";
import FileService from "@/services/FileService";
import { formatDate } from "@/shared/DateHelper";
import { mapGetters } from "vuex";
import pdf from "vue-pdf";
export default {
  name: "HistoryPage",
  components: {
    PortalSysbar,
    PortalAside,
    PortalTitle,
    pdf,
  },
  data() {
    return {
      loading:true,
      search: "",
      response: {
        status: "",
        message: "",
        show: false,
      },
      showDetail: false,
      contact: null,
      applications: [],
      show: true,
      pdfdialog: false,
      imgdialog: false,
      src: "",
      loadedRatio: 0,
      page: 1,
      numPages: 0,
      rotate: 0,
      imgSrc: "",
      opinion: "standby",
      options: {
        button: true,
        navbar: true,
        toolbar: true,
        tooltip: true,
        zoomable: true,
        rotatable: true,
        scalable: true,
        transition: true,
        fullscreen: true,
        keyboard: true,
      },
      application:null,
      headers: [
        {
          text: "Numero de la demande",
          value: "application.trackingNumber",
        },
        { text: "Demandeur", value: "applicant" },
        { text: "Avis", value: "application.opinion" },
        { text: "Decision", value: "application.status" },
        { text: "Action", value: "action" },
      ],
    };
  },
  methods: {
    loadApplication() {
      this.loading = true;
      let agent = "";
      if (this.isBasic()) {
        agent = this.getterUser.id;
      }
      ApplicationService.getOwnApplicationResolved(agent)
        .then((response) => {
          this.applications = response.data;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },

    isBasic() {
      let find = this.getterRoles.find((e) => e.authority == "basic");
      if (find !== undefined) {
        return true;
      }
      return false;
    },

    sendMail(applyNumber) {
      ApplicationService.resend(applyNumber)
        .then(() => {
          this.response = {
            message: "Mail envoyé avec succes",
            status: "success",
            show: true,
          };
        })
        .catch(() => {
          this.response = {
            message: "Erreur d'envoie de mail!",
            status: "error",
            show: true,
          };
        });
    },

    async getContact() {
      await ApplicationService.getContact(this.application.applicant.id).then(
        (response) => {
          this.contact = response.data;
        }
      );
    },

    getName(applicant) {
      return applicant.name + " " + applicant.surname;
    },

    getColor(state) {
      if (state === true) {
        return "success";
      } else if(state === false){
        return "error";
      }
      return ""
    },

    getStatus(state) {
      if (state === true) {
        return "Accepté";
      }else if(state ===false){
        return "Refusé";
      }
      return "En attente"
    },

    close() {
      this.showDetail = false;
    },
    displayItem(item) {
      this.application = item;
      this.showDetail = true;
      this.getContact();
    },
    format(date) {
      return formatDate(date);
    },
    loadImage(id) {
      return process.env.VUE_APP_MSDOC_URL + "/download/" + id;
    },

    loadFile(id) {
      FileService.getExtension(id).then((response) => {
        if (response.data.toLowerCase() == "pdf") {
          this.src = process.env.VUE_APP_MSDOC_URL + "/download/" + id;
          this.pdfdialog = true;
        } else {
          this.imgSrc = this.loadImage(id);
          this.imgdialog = true;
        }
      });
    },

    getGender(value) {
      if (value == "M") {
        return "Masculin";
      }
      return "Feminin";
    },
  },
  mounted() {
    this.loadApplication();
  },
  computed: {
    ...mapGetters("auth", {
      getterUser: "getUser",
      getterRoles: "getRoles",
    }),
  },
};
</script>

<style scoped>
.infoU .col {
  padding-bottom: 0px;
  padding-top: 0px;
}

.infoU .col:nth-child(1) {
  font-weight: bolder;
}
</style>