<template>
  <div>
    <v-navigation-drawer
      permanent
      app
      color="#00475A"
      style="margin-top: 58px; padding-bottom: 60px"
      dark
      width="239"
    >
      <v-list>
        <router-link to="/" exact-path v-show="!isGuard()">
          <v-list-item link>
            <v-list-item-icon>
              <v-icon> mdi-view-dashboard </v-icon>
            </v-list-item-icon>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item>
        </router-link>

        <router-link to="/visa" exact-path v-show="isGuard()">
          <v-list-item link>
            <v-list-item-icon>
              <v-icon> mdi-view-grid-plus-outline </v-icon>
            </v-list-item-icon>
            <v-list-item-title>Visa</v-list-item-title>
          </v-list-item>
        </router-link>

        <router-link to="/application" exact-path v-show="!isGuard()">
          <v-list-item link>
            <v-list-item-icon>
              <v-icon> mdi-view-grid-plus-outline </v-icon>
            </v-list-item-icon>
            <v-list-item-title>Demandes</v-list-item-title>
          </v-list-item>
        </router-link>

        <router-link to="/history" exact-path >
          <v-list-item link>
            <v-list-item-icon>
              <v-icon> mdi-history </v-icon>
            </v-list-item-icon>
            <v-list-item-title>Demandes traitees</v-list-item-title>
          </v-list-item>
        </router-link>

        <router-link to="/guard" exact-path>
          <v-list-item link>
            <v-list-item-icon>
              <v-icon> mdi-history </v-icon>
            </v-list-item-icon>
            <v-list-item-title>Demandes a la frontiere</v-list-item-title>
          </v-list-item>
        </router-link>
      </v-list>

      <template v-slot:append>
        <v-list>
          <v-list-item link @click="signOut">
            <v-list-item-icon>
              <v-icon> mdi-logout </v-icon>
            </v-list-item-icon>
            <v-list-item-title>Se deconnecter</v-list-item-title>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  methods: {
    ...mapActions("auth", {
      logout: "logout"
    }),
    signOut() {
      this.logout();
      this.$router.push("/login");
    },

    isBasic() {
      let find = this.getterRoles.find((e) => e.authority == "basic");
      if (find !== undefined) {
        return true;
      }
      return false;
    },

    isGuard() {
      let find = this.getterRoles.find((e) => e.authority == "guard");
      if (find !== undefined) {
        return true;
      }
      return false;
    },
  },
  data: () => ({}),
  computed: {
    ...mapGetters("auth", {
      getterRoles: "getRoles",
    }),
  },
};
</script>

<style scoped>
.v-list-item__title {
  font-size: 0.85em;
}

.v-list {
  padding: 0px;
}
</style>
