<template>
  <v-app>
    <portal-sysbar></portal-sysbar>
    <div class="main-content">
      <portal-aside></portal-aside>
      <portal-title title="Dashbord"></portal-title>
      <div class="content">
        <v-row>
          <v-col md="6">
            <v-card>
              <v-card-title> Demandes </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col>
                    <v-card outlined class="mb-0">
                      <v-card-text>
                        <h2 class="text-center">En attente</h2>
                        <div class="shift">{{ backlog }}</div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col>
                    <v-card outlined class="mb-0">
                      <v-card-text dark>
                        <h2 class="text-center">En cours</h2>
                        <div class="shift">{{ attribue }}</div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col>
                    <v-card outlined class="mb-0" color="">
                      <v-card-text>
                        <h2 class="text-center">Traites</h2>
                        <div class="shift">0</div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col md="6">
            <v-card>
              <v-card-title> Alertes sur vos dossiers </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col>
                    <v-card outlined class="mb-0" color="warning">
                      <v-card-text>
                        <h2 class="text-center">Urgent</h2>
                        <div class="shift">{{urgent}}</div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col>
                    <v-card outlined class="mb-0" color="yellow">
                      <v-card-text dark>
                        <h2 class="text-center">Moyen</h2>
                        <div class="shift">{{medium}}</div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col>
                    <v-card outlined class="mb-0" color="green">
                      <v-card-text dark>
                        <h2 class="text-center">Normal</h2>
                        <div class="shift">{{normal}}</div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col md="4">
            <v-card>
              <v-card-title> Taux d'acceptation </v-card-title>
              <v-card-text>
                <apex-charts
                  type="radialBar"
                  height="250"
                  :options="donutChartOptions"
                  :series="donutSeries"
                ></apex-charts>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col md="4">
            <v-card class="mx-auto">
              <v-card-title> Delai moyen de traitement </v-card-title>
              <v-card-text>
                <v-sheet class="px-auto">
                  <img
                    src="../assets/images/horloge.jpg"
                    width="105"
                    class="my-10"
                  />
                  <h1>{{ delay }}</h1>
                </v-sheet>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col md="4">
            <v-card>
              <v-card-title> Motif de visite </v-card-title>
              <v-card-text>
                <apex-charts
                  type="pie"
                  height="210"
                  :options="pieChartOptions"
                  :series="pieSeries"
                ></apex-charts>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col md="8">
            <v-card>
              <v-card-title> Demandes traitées par jour </v-card-title>
              <v-card-text>
                <apex-charts
                  type="bar"
                  height="250"
                  :options="chartOptions"
                  :series="series"
                ></apex-charts>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col md="4">
            <v-card>
              <v-card-title> Provenance (Top 10) </v-card-title>
              <v-card-text>
                <apex-charts
                  type="bar"
                  height="250"
                  :options="chartOptions2"
                  :series="series2"
                ></apex-charts>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-app>
</template>

<script>
import PortalSysbar from "@/components/PortalSysbar.vue";
import PortalAside from "@/components/PortalAside.vue";
import PortalTitle from "@/components/PortalTitle.vue";
import ApexCharts from "vue-apexcharts";
import StatistcService from "@/services/StatistcService";
import { firstDateOfCurrentMonth } from "@/shared/DateHelper";

export default {
  name: "Dashboard",
  data() {
    return {
      donutSeries: [],
      pieSeries: [],
      donutSerie2: [],
      backlog: 0,
      attribue: 0,
      urgent:0,
      medium:0,
      normal:0,
      delay: 0,
      stats: [],
      search: "",
      urgents: [],
      headers: [
        {
          text: "Date de la demande",
          align: "start",
          sortable: false,
          value: "date",
        },
        { text: "Numero", value: "numero" },
        { text: "Demandeur", value: "demandeur" },
        { text: "Date arrivée", value: "carbs" },
      ],
      pieChartOptions: {
        chart: {
          height: 350,
          type: "pie",
          redrawOnParentResize: true,
          toolbar: {
            show: true,
          },
          zoom: {
            type: "x",
            enabled: true,
            autoScaleYaxis: true,
          },
        },
        labels: ["Transit", "Work", "Family", "Tourist"],
        stroke: {
          width: 4,
        },
        dataLabels: {
          enabled: true,
          style: {
            colors: ["#111"],
          },
          background: {
            enabled: true,
            foreColor: "#fff",
            borderWidth: 0,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },

      donutChartOptions: {
        chart: {
          type: "radialBar",
          height: 350,
          offsetY: -10,
          toolbar: {
            show: true,
          },
          zoom: {
            type: "x",
            enabled: true,
            autoScaleYaxis: true,
          },
        },
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 135,
            hollow: {
              size: "50%",
            },
            dataLabels: {
              name: {
                show: true,
              },
              enabled: true,
              textAnchor: "middle",
              value: {
                show: true,
                fontSize: "30px",
                fontWeight: "bolder",
                color: "#4CAF50",
                offsetY: 76,
                formatter: function (val) {
                  return val.toFixed(2) + "%";
                },
              },
            },
          },
        },
        labels: ["Accepté"],
        fill: {
          colors: ["#7FC7B2"],
          type: "gradient",
          gradient: {
            shade: "dark",
            shadeIntensity: 0.15,
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 65, 91],
          },
        },
        stroke: {
          dashArray: 4,
        },
      },

      series: [
        {
          name: "ACCEPTEE",
          data: [],
        },
        {
          name: "REFUSEE",
          data: [],
        },
      ],
      series2: [
        {
          data: [],
        },
      ],
      chartOptions2: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          redrawOnParentResize: true,
          toolbar: {
            show: true,
          },
          zoom: {
            type: "x",
            enabled: true,
            autoScaleYaxis: true,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            //borderRadius: 10,
          },
        },
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          categories: [],
        },
        legend: {
          position: "right",
          offsetY: 40,
        },
        fill: {
          opacity: 1,
        },
      },

      chartOptions: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          redrawOnParentResize: true,
          toolbar: {
            show: true,
          },
          zoom: {
            type: "x",
            enabled: true,
            autoScaleYaxis: true,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            //borderRadius: 10,
          },
        },
        stroke: {
          curve: "smooth",
        },

        colors: ["#4CAF50", "#FF0000"],
        xaxis: {
          type: "datetime",
          tickPlacement: "between",
          categories: [],
        },
        legend: {
          position: "right",
          offsetY: 40,
        },
        fill: {
          opacity: 1,
        },
      },
    };
  },
  components: {
    PortalSysbar,
    PortalAside,
    ApexCharts,
    PortalTitle,
  },
  methods: {
    loadData() {
      StatistcService.getBacklog().then((response) => {
        this.backlog = response.data[0];
        this.attribue = response.data[1];
      });

      StatistcService.getPriority().then((response) => {
        this.urgent = response.data.urgent;
        this.medium = response.data.medium;
        this.normal = response.data.normal;
      });

      StatistcService.getDelayStat("2022-02-01").then((response) => {
        this.delay = response.data;
      });

      StatistcService.getReasonStat("2022-02-01").then((response) => {
        this.pieSeries[0] = response.data?.transit ?? 3;
        this.pieSeries[1] = response.data?.work ?? 2;
        this.pieSeries[2] = response.data?.family ?? 3;
        this.pieSeries[3] = response.data?.tourist ?? 1;
      });

      StatistcService.getStateStat("2022-02-01").then((response) => {
        this.series2 = [
          {
            data: response.data.data,
          },
        ];
        this.chartOptions2.xaxis.categories.splice(
          0,
          this.chartOptions2.xaxis.categories.length
        );
        let i = 0;
        for (i = 0; i < response.data.label.length; i++) {
          this.chartOptions2.xaxis.categories.push(response.data.label[i]);
        }
      });

      StatistcService.getTeamStat(firstDateOfCurrentMonth()).then(
        (response) => {
          this.stats = response.data;
          this.chartOptions.xaxis.categories.splice(
            0,
            this.chartOptions.xaxis.categories.length
          );
          let accepted = [];
          let refused = [];
          let taccepted = 0;
          let trefused = 0;
          let i = 0;
          for (i = 0; i < response.data.length; i++) {
            this.chartOptions.xaxis.categories.push(response.data[i].day);
            accepted.push(response.data[i].accepted);
            taccepted += response.data[i].accepted;
            refused.push(response.data[i].refused);
            trefused += response.data[i].refused;
          }
          let taux = (taccepted * 100) / (taccepted + trefused);
          this.donutSeries = [taux];
          this.series = [
            {
              name: "ACCEPTEE",
              data: accepted,
            },
            {
              name: "REFUSEE",
              data: refused,
            },
          ];
        }
      );
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style scoped>
.shift {
  display: block;
  text-align: center;
  font-weight: bolder;
  font-size: 25px;
}
</style>
