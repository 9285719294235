<template>
  <v-card class="card" outlined max-width="344" max-height="80" :color="background">
    <v-list-item three-line>
      <v-list-item-content class="text-center">
        <v-icon style="font-size: 20px;" :color="color">{{ icon }}</v-icon>
        <v-list-item-title class="headline mb-1 white--text">
          {{ nbr }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>
<script>
export default {
  props: {
    background: { type: String, required: true },
    color: { type: String, required: true },
    icon: { type: String, required: true },
    nbr: { type: Number, required: true },
  },
  data: () => ({})
};
</script>
<style scoped>
.card {
  border-radius: 10px;
}
</style>