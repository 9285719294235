import Vuex from 'vuex'
import Vue from 'vue'
import authModule from './modules/auth'
import appModule from './modules/application'

Vue.use(Vuex)


const store = new Vuex.Store({
    modules:{
        auth: authModule ,
        app: appModule
    }
})

export default store