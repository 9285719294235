import authHeader from './AuthHeader'
import axios from "axios";

const API_URL = process.env.VUE_APP_MSSTAT_URL

class StatisticService{

    getTeamStat(start){
        return axios.get(`${API_URL}/statistics/team?start=${start}`,{headers: authHeader()})
    }

    getReasonStat(start){
        return axios.get(`${API_URL}/statistics/reason?start=${start}`,{headers: authHeader()})
    }

    getStateStat(start){
        return axios.get(`${API_URL}/statistics/state?start=${start}`,{headers: authHeader()})
    }

    getDelayStat(start){
        return axios.get(`${API_URL}/statistics/delay?start=${start}`,{headers: authHeader()})
    }


    getBacklog(){
        return axios.get(`${API_URL}/statistics/backlog`,{headers: authHeader()})
    }

    getPriority(){
        return axios.get(`${API_URL}/statistics/priority`,{headers: authHeader()})
    }

    
}

export default new StatisticService()