import authHeader from './AuthHeader'
import axios from "axios";

const API_URL = process.env.VUE_APP_MSCORE_URL

class VisaService{

    getVisas(){
        return axios.get(`${API_URL}/visas`,{headers: authHeader()})
    }

    getBorderVisas(){
        return axios.get(`${API_URL}/visas/border`,{headers: authHeader()})
    }

    valid(payload){
        return axios.post(`${API_URL}/visa`, payload, {headers: authHeader()})
    }
    
}
export default new VisaService()