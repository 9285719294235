import axios from "axios";
import { tokenAlive, jwtDecrypt } from '@/shared/JwtHelper'
const AUTH_API_URL = process.env.VUE_APP_MSAUTH_URL


const state = () => ({
    token: "",
    tokenExp: "",
    id: null,
    username: "",
    fullname: "",
    email: "",
    roles: [],
    loginStatus: ""
});

const getters = {
    isTokenActive(state) {
        if (!state.tokenExp) {
            return false
        }

        return tokenAlive(state.tokenExp)
    },

    getUser(state) {
        return {
            id: state.id,
            username: state.username,
            email: state.email,
            fullname: state.fullname
        }
    },

    getLoginStatus(state) {
        return state.loginStatus
    },

    getRoles(state) {
        return state.roles
    },

    getToken(state){
        return state.token
    }


}

const mutations = {
    saveTokenData(state, data) {
        
        if (data == null || data == undefined) {
            if (window.localStorage) {
                localStorage.clear()
                state.token = ""
                state.tokenExp = ""
                state.id = null
                state.username = "",
                state.fullname = "",
                state.email = ""
                state.roles = []
                state.loginStatus = ""
            }
        } else {
            if (window.localStorage) {
                localStorage.setItem("access_token", data.accessToken)
            }
            const jwtDecodedValue = jwtDecrypt(data.accessToken)
            console.log(jwtDecodedValue)

            state.token = data.accessToken
            state.tokenExp = jwtDecodedValue.exp
            state.id = jwtDecodedValue.user.id
            state.username = jwtDecodedValue.user.username
            state.fullname = jwtDecodedValue.user.fullname
            state.email = jwtDecodedValue.user.email
            state.roles = jwtDecodedValue.user.authorities
        }

    },

    setLoginStatus(state, value) {
        state.loginStatus = value
    },
    setAccessToken(state, value) {
        state.token = value
    },

    setTokenExp(state) {
        state.tokenExp = ""
    },


}

const actions = {
    async login({commit}, payload){
        const response = await axios.post(`${AUTH_API_URL}/auth/signin`, payload)
        .catch(err => {
            console.log(err)
        })
        if(response && response.data){
            commit("saveTokenData", response.data)
            commit("setLoginStatus", "success")
        } else {
            commit("setLoginStatus", "failed")
        }
    },


    logout({commit}){
        localStorage.clear()
        commit("saveTokenData", null)
        commit("setLoginStatus", "failed")
    }

}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}