<template>
  <v-app>
    <v-container class="pt-10">
      <v-row class="justify-center pt-15">
        <v-card width="500" outlined class="mt-15 pb-5">
          <v-card-title>
            <v-row class="justify-center mt-5">
              <img
                src="../assets/images/logo.png"
                height="192px"
                alt=""
              />
            </v-row>
          </v-card-title>
          <v-card-text>
            <div>
              <v-text-field
                outlined
                dense
                color="#00475A"
                label="Nom d'utilisateur"
                append-icon="mdi-account-circle"
                v-model="user"
                :rules="rules"
              ></v-text-field>

              <v-text-field
                outlined
                dense
                color="#00475A"
                label="Mot de passe"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                v-model="pw"
                :rules="rules"
                :type="show ? 'text' : 'password'"
                hide-details
                class="pb-1 pp"
                @click:append="show = !show"
              ></v-text-field>

              <div class="text-center" v-show="this.loginFailed">
                <v-alert color="red" text dense> Error </v-alert>
              </div>

              <div>
                <a
                  href="#"
                  style="
                    color: #7b766a;
                    text-decoration: underline;
                    font-size: 12px;
                  "
                  @click="dialog = true"
                >
                  Mot de passe oublié
                </a>
              </div>

              <div class="pt-4">
                <v-btn color="success" block dark type="submit" @click="login()">
                  Se connecter
                </v-btn>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-row>

      <!--reset password Dialog-->
      <v-dialog v-model="dialog" max-width="500">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card>
            <v-card-title class="headline">
              Reinitialisation de mot de passe
            </v-card-title>

            <v-card-text>
              <v-alert dense text :type="dtype" dismissible v-model="alert">
                {{ dmessage }}
              </v-alert>

              <br />

              <v-text-field
                outlined
                dense
                :rules="rules"
                color="#00475A"
                label="Votre e-mail"
                append-icon="mdi-email"
                v-model="resetAccount"
              ></v-text-field>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn color="red darken-1" text @click="close"> Fermer </v-btn>

              <v-btn
                color="green darken-1"
                text
                @click="reset"
                :disabled="!valid"
              >
                Reinitialiser
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </v-container>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import PasswordService from '@/services/PasswordService'
export default {
  name: "Login",
  data() {
    return {
      dialog: false,
      valid: true,
      alert: false,
      dtype: "",
      dmessage: "",
      resetAccount: "",
      show: false,
      loginFailed: false,
      user: "",
      pw: "",
      rules: [(v) => !!v || "Ce champ est obligatoire"],
      loginValid: true,
    };
  },
  methods: {
      ...mapActions("auth", {
      actionLogin: "login",
    }),

    reset(){
      if (this.$refs.form.validate()) {
        PasswordService.sendLink(this.resetAccount)
        .then(() => {
            this.dtype = "success";
            this.dmessage =
              "Un lien de reinitialisation vous a été envoyé par mail";
            this.valid = false;
            this.alert = true;
          })
          .catch((error) => {
            this.dmessage = error.response.data;
            this.dtype = "error";
            this.alert = true;
          });

      }
      
    },

    isGuard() {
      let find = this.getterRoles.find((e) => e.authority == "guard");
      if (find !== undefined) {
        return true;
      }
      return false;
    },

    close() {
      this.dialog = false;
      this.resetAccount = "";
      this.alert = false;
      this.valid = true;
    },

    async login() {
      await this.actionLogin({ username: this.user, password: this.pw });
      if (this.getterLoginStatus === "success") {
        if(this.isGuard()){
          this.$router.push("/visa")
        }else{
          this.$router.push("/");
        }
        
      } else {
        this.loginFailed = true;
      }
    },

  },
  computed: {
      ...mapGetters("auth", {
      getterLoginStatus: "getLoginStatus",
      getterUser: "getUser",
      getterRoles: "getRoles"
    }),

  }
};
</script>

<style scoped>
</style>