<template>
    <v-row class="login-header">
      <div class="">
      </div>
      <v-spacer></v-spacer>
      <v-autocomplete>

      </v-autocomplete>
      
    </v-row>
</template>

<script>
export default {
  name: "PortalSysbar",
  data() {
    return {
      profils:[
        
      ]
    }
  },

};
</script>


<style scoped>
.login-header {
  margin: 0px auto;
  padding-left: 10px;
  padding-top: 10px;
  background-color: rgb(4, 109, 122);
  display: inline-flex;
  position: fixed;
  width: 100%;
  z-index: 99;
  height: 60px;
   display: inline-flex;
}


.title {
  padding-left: 145px;
  color: #00475a;
  text-transform: uppercase;
}

</style>
