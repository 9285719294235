import axios from "axios";
const API_URL = process.env.VUE_APP_MSAUTH_URL

class LoginService {
    initAccountCustomer(payload){
        return axios.post(`${API_URL}/auth/password`, payload)
    }

}

export default new LoginService();