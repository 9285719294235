<template>
    <v-app-bar window color="blue" height="55" dark app>   
            <v-toolbar-side-icon to="/">
                <v-icon></v-icon>
                <span>eVisa</span>
    
            </v-toolbar-side-icon>

            <v-spacer></v-spacer>

            <v-menu>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    icon
                    color="white"
                    dark
                    v-bind="attrs"
                    v-on="on"
                >
                Name
                </v-btn>
            </template>
            <v-list>
                <v-list-item
                v-for="(item, index) in items"
                :key="index"
                >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
            </v-list>
            </v-menu>

    </v-app-bar>
</template>
<script>
export default {
    name: 'Header',
    props: {
        image_url: String
    },
    data: () => ({
      items: [
        { title: 'Signout' },
      ],
    }),
    methods: {
        home(){
            this.$router.push("/");
        }
    }
}
</script>
<style scoped>
span {
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif;
    font-size: 20px;
}
</style>