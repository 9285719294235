<template>
  <v-app>
    <v-container class="pt-10">
      <v-row class="justify-center pt-15">
        <v-card width="500" outlined class="mt-15 pb-5">
          <v-card-title>
            <v-row class="justify-center mt-5">
              <img
                src="../assets/images/logo.png"
                height="192px"
                alt=""
              />
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-alert
              border="bottom"
              colored-border
              :type="type"
              elevation="2"
              v-show="alert"
              dismissible
            >
              <h2 class="title">Initialisation de mot de passe</h2>
              <div >
                {{message}}
              </div>
            </v-alert>
            <div>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field
                  outlined
                  dense
                  color="#00475A"
                  label="Mot de passe"
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  v-model="pw"
                  :rules="pwRules"
                  :type="show ? 'text' : 'password'"
                  hide-details="auto"
                  class="pb-1 pp"
                  @click:append="show = !show"
                ></v-text-field>
                <v-text-field
                  outlined
                  dense
                  color="#00475A"
                  label="Confirmer le mot de passe"
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  v-model="pwc"
                  :rules="pwcRules"
                  :type="show ? 'text' : 'password'"
                  hide-details="auto"
                  class="pb-1 pp"
                  @click:append="show = !show"
                ></v-text-field>

                <div class="pt-4">
                  <v-btn color="success" block dark @click="validate">
                    Creer
                  </v-btn>
                </div>
              </v-form>
            </div>
          </v-card-text>
        </v-card>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import LoginService from "@/services/LoginService";
export default {
  data() {
    return {
      pw: "",
      pwc: "",
      type:"success",
      alert:false,
      valid: true,
      show: false,
      message:"",
      pwRules: [
        (value) => !!value || "Mot de passe requis",
        (value) => (value && value.length >= 8) || "minimum 8 characteres",
        (value) =>
          /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/.test(value) ||
          "Le mot de passe doit contenir au moins une lettre minuscule, un chiffre, un caractère spécial et une lettre majuscule",
      ],
    };
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        let payload = {
          token: this.$route.params.token,
          password: this.pw,
        };
        console.log(payload);
        LoginService.initAccountCustomer(payload).then((response) => {
          this.message = response.data
          this.type = 'success'
          this.alert = true
        }).catch(err =>{
            this.message = err
          this.type = 'error'
          this.alert = true
        });
      }
    },
  },
  computed: {
    pwcRules() {
      return [
        (value) => !!value || "Mot de passe de confirmation requis",
        (value) =>
          value === this.pw || "Les mots de passe ne correspondent pas",
      ];
    },
  },
};
</script>

<style scoped>
</style>