import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import vuetify from './plugins/vuetify'
import router from '../router.js'
import VueViewer from 'v-viewer'
import './assets/styles/main.css'
import store from './store/index'
import 'viewerjs/dist/viewer.css'


Vue.use(VueRouter)
Vue.use(VueViewer)

Vue.config.productionTip = false

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App),
}).$mount('#app')
