export default class Demande {
    constructor(numero_demande, demandeur, date_demande, date_arrivee, date_depart, avis, date_avis, decision, date_decision, statut_arrive, date_statut, priorite){
        this.numero_demande = numero_demande,
        this.demandeur = demandeur,
        this.date_demande = date_demande,
        this.date_arrivee = date_arrivee,
        this.date_depart = date_depart,
        this.avis = avis;
        this.date_avis = date_avis;
        this.decision = decision;
        this.date_decision = date_decision;
        this.statut_arrive = statut_arrive;
        this.date_statut = date_statut;
        this.priorite = priorite
    }
}