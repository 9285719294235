import Vue from "vue";
import VueRouter from "vue-router";
import store from '@/store/index'
import Login from "@/pages/Login"
import BasicDashboard from "@/pages/basic/BasicDashboard"
import Dashboard from "@/pages/Dashboard"
import ApplicationPage from "@/pages/basic/ApplicationPage"
import HistoryPage from "@/pages/basic/HistoryPage"
import DetailsPage from '@/pages/DetailsPage'
import AccountPassword from '@/pages/AccountPassword'
import GuardPage from '@/pages/GuardPage'
import PasswordReset from '@/pages/PasswordReset'
import VisaPage from '@/pages/VisaPage'

Vue.use(VueRouter);

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/basic-dashboard',
        name: 'BasicDashboard',
        component: BasicDashboard,
        meta: {
            requiredAuth: true,
        }
    },
    {
        path: '/',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
            requiredAuth: true,
        }
    },
    {
        path: '/application',
        name: 'ApplicationPage',
        component: ApplicationPage,
        meta: {
            requiredAuth: true,
        }
    },
    {
        path: '/history',
        name: 'HistoryPage',
        component: HistoryPage,
        meta: {
            requiredAuth: true,
        }
    },
    {
        path: '/guard',
        name: 'GuardPage',
        component: GuardPage,
        meta: {
            requiredAuth: true,
        }
    },
    {
        path: '/visa',
        name: 'VisaPage',
        component: VisaPage,
        meta: {
            requiredAuth: true,
        }
    },
    {
        path: '/details',
        name: 'DetailsPage',
        component: DetailsPage,
        meta: {
            requiredAuth: true,
        }
    },
    {
        path: '/password/:token',
        name: 'AccountPassword',
        component: AccountPassword
    },
    {
        path: '/resetPassword/:token',
        name: 'PasswordReset',
        component: PasswordReset
    }
];

const router = new VueRouter({
    mode: 'history',
    routes
})


router.beforeEach(async (to, from, next) => {

    if (!store.getters["auth/getToken"]) {
        const token = localStorage.getItem("access_token");

        if (token && token !== `undefined`) {
            const data = {
                accessToken: token,
            };

            store.commit("auth/saveTokenData", data);
            store.commit("auth/setLoginStatus", "success");
        }
    }

    let auth = store.getters["auth/isTokenActive"];

    if (!auth && to.meta.requiredAuth) {
        return next({ path: "/login" });
    }

    return next();

})


export default router;