import authHeader from './AuthHeader'
import axios from "axios";

const API_URL = process.env.VUE_APP_MSDOC_URL

class FileService{

    getExtension(id){
        return axios.get(`${API_URL}/extension/${id}`,{headers: authHeader()})
    }
}

export default new FileService()