import axios from "axios";

const API_URL = process.env.VUE_APP_MSAUTH_URL

class PasswordService{

    sendLink(mail){
        return axios.get(`${API_URL}/auth/reset/${mail}`)
    }


    reset(payload){
        return axios.post(`${API_URL}/auth/reset`, payload)
    }

}

export default new PasswordService()